// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iphonecases-js": () => import("./../../../src/pages/iphonecases.js" /* webpackChunkName: "component---src-pages-iphonecases-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-returnpolicy-js": () => import("./../../../src/pages/returnpolicy.js" /* webpackChunkName: "component---src-pages-returnpolicy-js" */),
  "component---src-pages-samsungcases-js": () => import("./../../../src/pages/samsungcases.js" /* webpackChunkName: "component---src-pages-samsungcases-js" */),
  "component---src-pages-termsofservice-js": () => import("./../../../src/pages/termsofservice.js" /* webpackChunkName: "component---src-pages-termsofservice-js" */),
  "component---src-templates-product-categories-template-js": () => import("./../../../src/templates/ProductCategoriesTemplate.js" /* webpackChunkName: "component---src-templates-product-categories-template-js" */),
  "component---src-templates-product-detail-template-js": () => import("./../../../src/templates/ProductDetailTemplate.js" /* webpackChunkName: "component---src-templates-product-detail-template-js" */)
}

