import React, { useState, createContext, useEffect } from 'react'
import Client from 'shopify-buy'

const client = Client.buildClient({
    domain: "colorfulphonecases-checkout.myshopify.com",
    storefrontAccessToken: "8685cf714e573b00546849395e9a8fc3"
})

const defaultValues = {
    isCartOpen: false,
    toggleCartOpen: () => { },
    cart: [],
    addProductToCart: () => { },
    client,
    checkout: {
        lineItems: [],
    },
}

export const StoreContext = createContext(defaultValues)

const isBrowser = typeof window !== "undefined"

export const StoreProvider = ({ children }) => {
    const [checkout, setCheckout] = useState(defaultValues.checkout)
    const [isCartOpen, setCartOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const toggleCartOpen = () => {
        setCartOpen(!isCartOpen)
    }


    useEffect(() => {
        initializeCheckout()
    }, [])

    const getNewId = async () => {
        try {
            const newCheckout = await client.checkout.create();

            if (isBrowser) {
                localStorage.setItem("checkout_id", newCheckout.id)
            }
            return newCheckout
        } catch (e) {
            setLoading(false)
            console.error(e)
        }
    }


    const initializeCheckout = async () => {
        try {
            // Check if id exists
            const currentCheckoutId = isBrowser
                ? localStorage.getItem("checkout_id")
                : null

            let newCheckout = null

            if (currentCheckoutId) {

                // If id exists, fetch checkout from Shopify
                newCheckout = await client.checkout.fetch(currentCheckoutId)
                //if checkout is completed
                if (newCheckout.completedAt) {
                    newCheckout = await getNewId()
                }
            } else {

                // If id does not, create new checkout
                newCheckout = await getNewId()
            }

            setCheckout(newCheckout)

        } catch (e) {
            setLoading(false)
            console.error(e)
        }
    }

    //add to cart
    const addProductToCart = async (variantId) => {

        try {
            setLoading(true)
            const lineItems = [
                {
                    variantId,
                    quantity: 1
                },
            ]
            console.log("id", checkout.id);
            //adds the added products in shopifys checkout
            const newCheckout = await client.checkout.addLineItems(
                checkout.id,
                lineItems
            )

            console.log("new Checkout", newCheckout);

            //updates our checkout after add product
            setCheckout(newCheckout)

            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.error(e)
        }
    }

    //remove from cart
    const RemoveProductfromCart = async (lineItemId) => {

        try {
            setLoading(true)
            const newCheckout = await client.checkout.removeLineItems( //remove the products in shopifys checkout
                checkout.id,
                [lineItemId]
            )
            //updates our checkout after add product
            setCheckout(newCheckout)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.error(e)
        }
    }
    //remove from cart
    const RemoveOneProductfromCart = async (Item) => {

        let quantity = Item.quantity;
        quantity--;

        try {
            setLoading(true)

            if (Item.quantity == 1) {
                RemoveProductfromCart(Item.id);
            } else {

                const lineItems = [{ id: Item.id, quantity: quantity }];
                const newCheckout = await client.checkout.updateLineItems( //remove the products in shopifys checkout
                    checkout.id,
                    lineItems
                )
                setCheckout(newCheckout)
            }


            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.error(e)
        }
    }

    //add coupon
    const checkCoupon = async (coupon) => {
        setLoading(true)
        const newCheckout = await client.checkout.addDiscount(checkout.id, coupon);
        setCheckout(newCheckout)
        setLoading(false)
    }

    //remove a coupon
    const removeCoupon = async (coupon) => {
        setLoading(true)
        const newCheckout = await client.checkout.removeDiscount(checkout.id, coupon);
        setCheckout(newCheckout)
        setLoading(false)
    }



    return (
        <StoreContext.Provider
            value={{
                ...defaultValues,
                checkout,
                addProductToCart,
                toggleCartOpen,
                isCartOpen,
                RemoveProductfromCart,
                RemoveOneProductfromCart,
                checkCoupon,
                removeCoupon,
                isLoading

            }}
        >
            {children}
        </StoreContext.Provider>
    )
}
